var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"card mb20"},[_c('router-link',{attrs:{"to":{
                path: '/reportdetails',
                query: {
                  status: 'In Scheduling',
                  //acid: reports.backlog[0].acid,
                },
              }}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex p-10 no-block"},[_c('div',{staticClass:"align-slef-center"},[(_vm.homeReports && _vm.homeReports.length)?_c('h4',{staticClass:"m-b-0"},[_vm._v(_vm._s(_vm._f("currency")(_vm.homeReports[3].sales)))]):_c('h3',{staticClass:"m-b-0"},[_vm._v("...")]),_c('div',{staticClass:"progress",staticStyle:{"background":"#fff"}},[(_vm.homeReports && _vm.homeReports[3].sales == 0)?_c('div',{staticClass:"progress-bar bg-success",staticStyle:{"width":"20%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])]):_c('div',{staticClass:"progress-bar bg-success",staticStyle:{"width":"100%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])])]),_c('h6',{staticClass:"text-muted m-b-0"},[_vm._v("In Scheduling")])]),_c('div',{staticClass:"align-self-center display-6 ml-auto"},[_c('img',{staticClass:"db-image",attrs:{"src":require("@/assets/img/calendar-todo-line.svg")}})])])])])],1)]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"card mb20"},[_c('router-link',{attrs:{"to":{
                path: '/reportdetails',
                query: {
                  status: 'In Production',
                  //acid: reports.backlog[0].acid,
                },
              }}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex p-10 no-block"},[_c('div',{staticClass:"align-slef-center"},[(_vm.homeReports && _vm.homeReports.length)?_c('h4',{staticClass:"m-b-0"},[_vm._v(_vm._s(_vm._f("currency")(_vm.homeReports[2].sales)))]):_c('h3',{staticClass:"m-b-0"},[_vm._v("...")]),_c('div',{staticClass:"progress",staticStyle:{"background":"#fff"}},[(_vm.homeReports && _vm.homeReports[2].sales == 0)?_c('div',{staticClass:"progress-bar bg-info",staticStyle:{"width":"20%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])]):_c('div',{staticClass:"progress-bar bg-info",staticStyle:{"width":"100%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])])]),_c('h6',{staticClass:"text-muted m-b-0"},[_vm._v("In Production")])]),_c('div',{staticClass:"align-self-center display-6 ml-auto"},[_c('img',{staticClass:"db-image",attrs:{"src":require("@/assets/img/stack-line.svg")}})])])])])],1)]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"card mb20"},[_c('router-link',{attrs:{"to":{
                path: '/reportdetails',
                query: {
                  status: 'In Shipping',
                  //acid: reports.backlog[0].acid,
                },
              }}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex p-10 no-block"},[_c('div',{staticClass:"align-slef-center"},[(_vm.homeReports && _vm.homeReports.length)?_c('h4',{staticClass:"m-b-0"},[_vm._v(_vm._s(_vm._f("currency")(_vm.homeReports[4].sales)))]):_c('h3',{staticClass:"m-b-0"},[_vm._v("...")]),_c('div',{staticClass:"progress",staticStyle:{"background":"#fff"}},[(_vm.homeReports && _vm.homeReports[4].sales == 0)?_c('div',{staticClass:"progress-bar bg-primary",staticStyle:{"width":"20%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])]):_c('div',{staticClass:"progress-bar bg-primary",staticStyle:{"width":"100%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])])]),_c('h6',{staticClass:"text-muted m-b-0"},[_vm._v("In Shipping")])]),_c('div',{staticClass:"align-self-center display-6 ml-auto"},[_c('img',{staticClass:"db-image",attrs:{"src":require("@/assets/img/truck-line.svg")}})])])])])],1)]),_c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"card mb20"},[_c('router-link',{attrs:{"to":{
                path: '/reportdetails',
                query: {
                  status: 'Backlog',
                  //acid: reports.backlog[0].acid,
                },
              }}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex p-10 no-block"},[_c('div',{staticClass:"align-slef-center"},[(_vm.loading)?_c('h3',{staticClass:"m-b-0"},[_vm._v("...")]):(!_vm.loading && _vm.homeReports && _vm.homeReports.length)?_c('h4',{staticClass:"m-b-0"},[_vm._v(_vm._s(_vm._f("currency")(_vm.homeReports[0].sales)))]):_vm._e(),_c('div',{staticClass:"progress",staticStyle:{"background":"#fff"}},[(_vm.homeReports && _vm.homeReports[0].sales == 0)?_c('div',{staticClass:"progress-bar bg-danger",staticStyle:{"width":"20%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])]):_c('div',{staticClass:"progress-bar bg-danger",staticStyle:{"width":"100%","height":"3px"},attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"sr-only"},[_vm._v("50% Complete")])])]),_c('h6',{staticClass:"text-muted m-b-0"},[_vm._v("Backlog")])]),_c('div',{staticClass:"align-self-center display-6 ml-auto"},[_c('img',{staticClass:"db-image",attrs:{"src":require("@/assets/img/briefcase-line.svg")}})])])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }